import React, { useState, useContext } from "react"
import Hero from "../components/Hero"
import Experts from "../components/Experts"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { graphql } from "gatsby"
import { StateContext } from "../helpers/StateProvider"
import exportIcon from "../assets/icons/lawyer.svg";
import expertImage from "../assets/images/lawyer.webp";
const Lawyer = props => {
  const { authIsReady } = useAuthContext()
  let inverted = true
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {props.data.allPropertyType.edges && props.data.allPropertyType.edges && (
        <>
          <Seo title="Graphic Designer" />
          <Hero
            arabic={language}
            title={
              language == false
                ? "Lawyer"
                : "محامي"
            }
            subTitle={
              language == false
                ? "Advocating for Justice: Legal Expertise with a Personal Touch"
                : "الدفاع عن العدالة: الخبرة القانونية بلمسة شخصية"
            }
          />
          {/* <Widgets widgetsData='property-type'/>  */}

      
              <Experts
                inverted={false}
                Icon={exportIcon}
                image={expertImage}
                title={
                  language == false
                    ? "Lawyer"
                    : "محامي"
                }
                description={
                  language == false
                  ? "Legal challenges can be overwhelming, but you don't have to face them alone. OPD's lawyers are here to provide the expert guidance and support you need to navigate the legal system successfully.Together, we'll tackle your legal challenges head-on, ensuring that you're not just represented, but truly advocated for, every step of the way."
                  : "يمكن أن تكون التحديات القانونية ساحقة، لكن ليس عليك مواجهتها بمفردك. محامو OPD متواجدون هنا لتقديم إرشادات الخبراء والدعم الذي تحتاجه للتنقل عبر النظام القانوني بنجاح. معًا، سنتعامل مع تحدياتك القانونية بشكل مباشر، مما يضمن أنك لست ممثلاً فقط ، ولكن تم الدفاع عنه حقًا في كل خطوة على الطريق."    
                }
                label={
                  language == false
                  ? "Call Our Lawyer"
                  : "اتصل بمحامينا"
                }
                link={"tel:+9613948739"}
                arabic={language}
              />
          <br/><br/><br/>
        </>
      )}
    </>
  )
}

export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allPage(filter: { name: { eq: "property-type" } }) {
      edges {
        node {
          name
          title
          title_ar
          id
          description
          description_ar
          widgets {
            name
            type
          }
        }
      }
    }
    allPropertyType {
      edges {
        node {
          description
          description_ar
          id
          image
          link
          title
          title_ar
          value
          subcategories {
            label
            label_ar
            value
          }
          localImage {
            childImageSharp {
              fixed(width: 1000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default Lawyer
